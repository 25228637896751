<template>
  <b-modal
    id="modal-industry"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    hideHeaderClose
    no-close-on-backdrop
    @ok.prevent="saveData"
    @cancel="resetModal"
    @shown="showModal"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder">
            {{ pinpoint.label }}
          </h2>
          <!-- <span>- {{ $t("pin_point.insert_description") }} -</span> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('pin_point.pin_description')">
            <Vueditor id="editor_industry"></Vueditor>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";

import { getPinpoint, updatePinpoint } from "@api/pinpoint";
import { createEditor } from "vueditor";

export default {
  props: ["pinpoint"],
  name: "pin-point-industry",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BFormFile,
    BOverlay,
    FooterPinPoint,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      original_pin: null,
      hide_pinpont: !this.pinpoint.active,
      show: false,
      editor: null,
    };
  },
  methods: {
    showModal() {
      this.editor = createEditor("#editor_industry");
      this.editor.upload = function(obj, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(obj.files[0]);
        reader.onloadend = function() {
          callback(reader.result);
        };
      };
      const $this = this;
      getPinpoint($this.pinpoint.id)
        .then((data) => {
          $this.original_pin = data;
          if (data.fields.text != null) {
            $this.editor.setContent(data.fields_html_content[data.fields.text]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveData() {
      const $this = this;
      let formData = new FormData();
      this.show = true;
      formData.append("text", $this.editor.getContent());
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-industry");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-industry");
      this.editor.setContent(this.original_pin.fields.text);
      this.hide_pinpont = !this.pinpoint.active;
    },
  },
};
</script>

<style></style>
