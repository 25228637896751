<template>
  <b-modal
    id="modal-social"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    hideHeaderClose
    no-close-on-backdrop
    @ok.prevent="saveData"
    @cancel="resetModal"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder">
            {{ pinpoint.label }}
          </h2>
          <span>{{ $t("pin_point.social_info") }}</span>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="facebook">Facebook</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="FacebookIcon" />
              </b-input-group-prepend>
              <b-form-input id="facebook" v-model="fields.facebook" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="instagram">Instagram</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="InstagramIcon" />
              </b-input-group-prepend>
              <b-form-input id="instagram" v-model="fields.instagram" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="linkedin">Linkedin</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="LinkedinIcon" />
              </b-input-group-prepend>
              <b-form-input id="linkedin" v-model="fields.linkedin" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="twitter">Twitter</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="TwitterIcon" />
              </b-input-group-prepend>
              <b-form-input id="twitter" v-model="fields.twitter" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="youtube">Youtube</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="YoutubeIcon" />
              </b-input-group-prepend>
              <b-form-input id="youtube" v-model="fields.youtube" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";

import { updatePinpoint } from "@api/pinpoint";

export default {
  props: ["pinpoint"],
  name: "pin-point-social",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BInputGroup,
    BInputGroupPrepend,
    BOverlay,
    FooterPinPoint,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: { ...this.pinpoint.fields },
      hide_pinpont: !this.pinpoint.active,
      show: false,
    };
  },
  methods: {
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      formData.append("facebook", $this.fields.facebook);
      formData.append("instagram", $this.fields.instagram);
      formData.append("linkedin", $this.fields.linkedin);
      formData.append("twitter", $this.fields.twitter);
      formData.append("youtube", $this.fields.youtube);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-social");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-social");
      this.fields = { ...this.pinpoint.fields };
      this.hide_pinpont = !this.pinpoint.active;
    },
  },
};
</script>

<style></style>
